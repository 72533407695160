<!-- pc登录页 -->
<template>
  <div class="vip_login_p">
    <div class="vip_container">
      <div class="login-box">
        <div
          class="login-warp-new"
          v-if="LoginRegister"
          style="margin-top: 20px"
        >
          <div class="logo-box">
            <span style="font-size: 24px; font-weight: 700">
              分站管理后台
            </span>
          </div>

          <div class="loginmodule collapse in" style="margin-top: 30px">
            <div>
              <el-tabs
                @tab-click="handleClick"
                class="loginp-tabs"
                v-model="activeName"
              >
                <!-- 账号密码登录 -->
                <el-tab-pane
                  label="账号密码登录"
                  name="accountLogin"
                  v-if="setting.accountLogin"
                >
                  <div class="sign-in">
                    <el-form
                      :model="loginForm"
                      status-icon
                      :rules="rules"
                      ref="loginForm"
                    >
                      <el-form-item prop="username">
                        <el-input
                          v-model="loginForm.username"
                          placeholder="请输入账号"
                        ></el-input>
                      </el-form-item>

                      <el-form-item prop="password">
                        <el-input
                          placeholder="请输入密码"
                          type="password"
                          v-model="loginForm.password"
                          autocomplete="off"
                          show-password
                        ></el-input>
                      </el-form-item>
                    </el-form>

                    <el-button
                      style="width: 100%"
                      type="primary"
                      @click="handleLogin('loginForm')"
                      >登录
                    </el-button>
                  </div>
                </el-tab-pane>

                <el-tab-pane
                  label="短信登录"
                  name="phoneLogin"
                  v-if="setting.phoneLogin"
                >
                  <el-form
                    :model="loginForm"
                    status-icon
                    :rules="rules"
                    ref="phoneLoginForm"
                  >
                    <el-form-item prop="phoneNumber">
                      <el-input
                        v-model="loginForm.phoneNumber"
                        placeholder="请输入手机号"
                      ></el-input>
                    </el-form-item>

                    <el-form-item prop="smsVerificationCode">
                      <el-input
                        placeholder="请输入验证码"
                        v-model="loginForm.smsVerificationCode"
                      >
                        <template
                          slot="append"
                          style="background-color: #0065fd"
                        >
                          <el-button
                            type="primary"
                            :disabled="countdown > 0"
                            @click="startCountdown()"
                            >{{ buttonText }}</el-button
                          >
                        </template>
                      </el-input>
                    </el-form-item>

                    <el-form-item style="text-align: center">
                      <el-button
                        style="width: 100%"
                        type="primary"
                        @click="phoneLogin('phoneLoginForm')"
                        >登录</el-button
                      >
                    </el-form-item>
                  </el-form>
                </el-tab-pane>
              </el-tabs>

              <!-- 快捷登录 -->
              <div class="third-login" v-if="this.setting.quickLogin">
                <el-divider>快捷登录</el-divider>
                <div style="display: flex; justify-content: center">
                  <div
                    v-for="(item, index) in quickLoginList"
                    :key="index"
                    @click="jhLoginForm(item.type)"
                    style="padding: 0px 10px"
                  >
                    <img
                      v-if="item.type === 'qq'"
                      class="login_icon"
                      src="@/assets/icon/qq.svg"
                    />

                    <img
                      v-if="item.type === 'wx'"
                      class="login_icon"
                      src="@/assets/icon/login_weixin.svg"
                    />

                    <img
                      v-if="item.type === 'alipay'"
                      class="login_icon"
                      src="@/assets/icon/icon_zhifubao.svg"
                    />
                  </div>
                </div>
              </div>
              <div class="login-bottom">
                <span>登录即表示已阅读并同意</span>
                <el-button type="text" @click="dialogUseAgreement = true">
                  《用户协议》
                </el-button>
                <el-button type="text" @click="dialogPrivacyAgreement = true">
                  《隐私权政策》
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="copyt-right-box">
        <div class="glmall-copyright-group">
          <span v-html="webSetting.detail.beianCode"></span>
        </div>
      </div>
    </div>

    <el-dialog
      title="用户协议"
      :visible.sync="dialogUseAgreement"
      center
      append-to-body
    >
      <div v-html="xieyiObj.useAgreement"></div>
    </el-dialog>

    <el-dialog
      title="隐私协议"
      :visible.sync="dialogPrivacyAgreement"
      center
      append-to-body
    >
      <div v-html="xieyiObj.privacyAgreement"></div>
    </el-dialog>

    <!-- 首页弹框 -->
    <!-- <el-dialog
      :destroy-on-colse="true"
      title="温馨提示"
      :visible.sync="loginNoticeVisible"
      width="35%"
      append-to-body
    >
      <div class="ql-editor" v-html="loginNoticeContent" />
    </el-dialog> -->
  </div>
</template>
<script>
import { Toast } from "vant";
import { getUserId } from "@/utils/auth";
import { getSysXieYi, getQuickLogin } from "@/api/index";
import { getScanLogin, sendPhoneCode } from "@/api/user";
import { mapState } from "vuex";

// import Captcha from "@/components/captcha/CaptchaB.vue";

export default {
  components: {},

  data() {
    var validateUserName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入账号"));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "" || value.length < 6) {
        callback(new Error("请输入不少于6位的密码"));
      } else {
        if (this.loginForm.checkPass !== "") {
          this.$refs.registerForm.validateField("checkPass");
        }
        callback();
      }
    };

    var validateCheckPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.loginForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };

    var validateCheckPhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        callback();
      }
    };

    var validateCheckVerificationCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else {
        callback();
      }
    };
    return {
      dialogPrivacyAgreement: false,
      dialogUseAgreement: false,
      getUserId: getUserId(),
      rules: {
        username: [{ validator: validateUserName, trigger: "blur" }],
        password: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validateCheckPass, trigger: "blur" }],
        phoneNumber: [{ validator: validateCheckPhone, trigger: "blur" }],
        smsVerificationCode: [
          { validator: validateCheckVerificationCode, trigger: "blur" },
        ],
      },
      dialogLoginVisible: true,
      loginForm: {
        username: "",
        password: "",
        checkPass: "",
        inviterCode: "",
        phoneNumber: "",
        smsVerificationCode: "",
        platformType: "1",
        //验证码
        validateCaptcha: undefined,
      },

      xieyiObj: {},
      countdown: 0,
      buttonText: "获取验证码",
      activeName: "",
      inviter_code: "",
      //true是登录 false是注册
      LoginRegister: true,
      loginNoticeVisible: false,
      loginNoticeContent: undefined,
      quickLoginList: [],
      verifyStatus: false,
    };
  },
  computed: {
    ...mapState({
      //查询网站系统配置
      setting: (state) => state.index.setting,
      webSetting: (state) => state.index.webSetting,
    }),
  },

  mounted() {
    // // 根据后台返回的值设置默认选中的标签页
    if (this.setting.accountLogin) {
      this.activeName = "accountLogin";
    } else if (this.setting.phoneLogin) {
      this.activeName = "phoneLogin";
    } else {
      this.activeName = "accountLogin";
    }
  },
  created() {
    //加载协议
    this.loadXieYi();

    //加载快捷登录配置
    this.loadQuickLogin();
  },
  methods: {
    /**
     * 验证成功
     */
    success(params) {
      //验证成功
      this.verifyStatus = true;

      this.loginForm.validateCaptcha = params.captchaVerification;
    },

    /**
     * 加载快捷登录配置
     */
    loadQuickLogin() {
      new Promise((resolve, rejust) => {
        getQuickLogin()
          .then((res) => {
            this.quickLoginList = res.result;
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 账号密码登录
     */
    handleLogin(formName) {
      console.log("=============================");
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$store
            .dispatch("user/login", this.loginForm)
            .then(() => {
              this.dialogLoginVisible = false;
              Toast.success({
                message: "登陆成功",
              });

              this.$router.push("/vip");
            })
            .catch(() => {});
        } else {
          return false;
        }
      });
    },

    /**
     * 手机号登录
     */
    phoneLogin(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.inviter_code) {
            this.loginForm.inviterCode = this.inviter_code;
          }
          this.$store
            .dispatch("user/phoneLogin", this.loginForm)
            .then(() => {
              this.dialogLoginVisible = false;
              Toast.success({
                message: "登陆成功",
              });

              this.$router.push("/vip");
            })
            .catch(() => {});
        } else {
          return false;
        }
      });
    },

    /**
     * 切换登录
     */
    handleClick() {
      this.$refs.loginForm.resetFields();
    },

    /**
     * 发送获取验证码
     */
    startCountdown() {
      new Promise((resolve, rejust) => {
        sendPhoneCode(this.loginForm)
          .then((res) => {
            if (res.status == 0) {
              Toast.success({
                message: "已发送",
              });

              this.countdown = 60;
              this.buttonText = `${this.countdown} 秒后重试`;

              const timer = setInterval(() => {
                if (this.countdown > 0) {
                  this.countdown--;
                  this.buttonText = `${this.countdown} 秒后重试`;
                } else {
                  clearInterval(timer);
                  this.buttonText = "获取验证码";
                }
              }, 1000);
            }
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    loadXieYi() {
      new Promise((resolve, rejust) => {
        getSysXieYi()
          .then((res) => {
            //主站
            this.xieyiObj = res.result;

            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    closeLogin() {
      this.dialogLoginVisible = false;
      this.$emit("close"); // 触发关闭事件，通知父组件
    },

    /**
     * 聚合登录
     */
    jhLoginForm(type) {
      var data = {
        loginType: type,
      };
      new Promise((resolve, rejust) => {
        getScanLogin(data)
          .then((res) => {
            // this.domainList = res.result.list;
            if (res.result.code === 0) {
              // 获取第三方URL
              var url = res.result.url;

              // 在当前窗口中打开第三方URL
              window.location.href = url;
            }
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },
  },
};
</script>
<style>
.vip_login_p {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-image: url(../../assets/vip_bj.png);
  background-size: cover;
  background-position: 50%;
}

.vip_login_p .vip_container {
  /* position: relative; */
  height: 100%;
}

.vip_login_p .vip_container:after,
.vip_login_p .vip_container:before {
  display: table;
  content: "";
}

/* 电脑屏幕 */
@media (min-width: 1025px) {
  .vip_login_p .vip_container .login-box {
    width: 480px;
  }
}

/* 手机屏幕 */
@media (max-width: 1024px) {
  .vip_login_p .vip_container .login-box {
    width: 90%;
  }
}

.vip_login_p .vip_container .login-box {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  min-height: 550px;
  padding: 20px;
  border-radius: 4px;
  background: #fff;
  -webkit-box-shadow: 0 4px 12px 0 rgba(0, 54, 103, 0.30196078431372547);
  box-shadow: 0 4px 12px 0 rgba(0, 54, 103, 0.30196078431372547);
}

.vip_login_p .vip_container .login-box .login-warp-new {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.vip_login_p .vip_container .login-box .login-warp-new .collapse {
  display: none;
}

.vip_login_p .vip_container .login-box .login-warp-new .collapse.in {
  display: block;
}

.vip_login_p .vip_container .login-box .login-warp-new .logo-box {
  margin-top: 30px;
}

.vip_login_p .vip_container .login-box .login-warp-new .logo-box .logo-img {
  display: block;
  width: 180px;
  height: 80px;
}

.vip_login_p .vip_container .login-box .login-warp-new .toggle-box {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 48px;
  height: 48px;
  background-size: cover;
  cursor: pointer;
}

.vip_login_p .vip_container .login-box .login-warp-new .qrcode {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARqSURBVHgB7dy/T9RgHMfxT9FEN9kcHMRFN2XRRBMVBt38sTgaMdEZjHE09OIfAER3ILoLOjKAOMnCiRNO4KQs4qROZz8tFw/o02ufPm2f9vm+BkWuMXrv/nyO5/E6nY4PYBIG7f4F5j8DK9+A9g9gaxdOGhoEhk8CV06h/ewy2sG3Wp7nbfVu4/EXkxEWvgIP30URxH9DJwD/GvDgPKYRhQh3S6+7gYkIT5aA6TWIBIwweTU8GkYZYaD7QvAHP/itBU2tj/Lmp+GvhjvqMPZ2du/gBjpHwtxGcNp5D5HB23vA3bPBURD3YtYIZ14FF9pf6tcHjwFjF4ALJ6NzYZPt/gEWg+vgwmbydXDwOPDlMWY81QZpI/Tb+4PKmL0dRXAJd8jWavT+qPhXsTWgejHtNWFxU/3a+KXoUHPtzSce6bO3gImL6m3aOxgaSPpL0kRY34HyHzB9A86buhk9C8RpfwcSA1C/CNuKh6zJaxB7phQ7Ik9TfQNQ1ltUXmDGzkPsGTmtPg2nCkBZIqgOOZcxQpyjyIARgrsjfmls7Ii3agubqB0e5cMZbqu5fZxMAchkhJm16MmwzuNGe0ML2lKfgnrlHbYg3h9PLNV/0I470PwGtGkFoLwRZj6hMfKMgWkHoDBCRy9CeweNwc88dOUKEHquH0GYCEASQZuZACQRtJgLQBIhM7MBKIgQfBgtEVIyHyAwdyv/c4IrCglAJh7WXFBYAJII/RUagCRCssIDkERQKyUASYR4pQUgiXBYqQFIIuxXegDKM4raNJUECMmwRai6ACQRKg5AjkeoPgA5HMGOAORoBHsCkIMR7ApAjkWwLwA5FMHOAORIBHsDkAMR7A5ANYiQZ96b/QHI8ggPcsyFqEcAsjQCf+7fzzEbKPOPp1eKP/LyssP/tNG1LXTdORfNAs3DQw0VscBI0UbfACvbh79fn1NQjzp+qKNaMaaWAahOEcIlexQrCdQ2ANUlwoxiAgfnjdU6ANkegW++arkCTvKrfQCyNQLffM6DU+HzQ71uQxMUMYVWR3faLSfuxd319OIzRGMCECOMvO7wgldJhN3f6Wd9cmorhzBq+RzQ14vgOcGz9zmB5/71R9HXjbgGHGLx2BHffC7h09XMAGRhBK6ftHx//+hpM09BvSo+HXGVlLvnojueuAU7mh8g4H/o+NcrGMDjns7FW5M4EYBsHcBzJgDZGMGpAGRbBOcCkE0RnAxAtkRwNgDZEMHpAKEUzwm8neTSy3mWJlM5Atctt1Yw6ntBhBHVJhxg48gm91bV6oe65AjoSnEk8BOsn09hVHPHgrKqaOxIAvTqE2H8IoyTU1CcA6cjDqhxJNOX9bDLw1vUTgkg1MqIAJGs6AgQ/RUZASKdoiJApFdEBIhsTEeAyM5kBAg9piJA6DMRASKfvBEg8ssTAcIM3QgQ5uhEgDArawQI87JEgChG2ggQxUkTAaJY/SJAFC8pAkQ5VBEgyhMXAaJcByNAlK83AkQ1uhEgqsMI/wD10qncUVnweQAAAABJRU5ErkJggg==);
}

.vip_login_p .vip_container .login-box .login-warp-new .qrcode:before {
  content: "";
  position: absolute;
  left: -104px;
  top: -2px;
  display: inline-block;
  width: 106px;
  height: 32px;
  background-size: cover;
}

.vip_login_p .vip_container .login-box .login-warp-new .pwd {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKfSURBVHgB7dzBbdswFIDhRyG9e4SO4G7SFbqBe09RF8m93aArNJM0I3SE3GtAFdWmiB1bIi2R75H8PyBB4thA7F+2RZGyE2/fv5Ub+T78tB2+NmKNkx/yWz7K3v0Kun5B98eNF9z3u+H7V7HtSQ7ybjbC3wf/p1h84I+N96cbf7x136SXL2Lb5t9WPe3NuCFZf/C98f50/3/95PYFRNjOXqOX91KObXf0q/0IJWzZMTbdq4vKeCZUozt7KRGy6S7+hQhZOLHmvu8n/37rXNLbr23m/+kEqgigjADKCKCMAMoIoIwAym5kqbt+P4wmPgdfP/d+eKy5ccSphfdn+TOAEfMi67wEEeFq670HEOEq674JEyHa+ntBRIiSZjeUCMHSjQOIECTtQIwIs9KPhIkwKc+hCCJctPxQRCgf4S5ulN+CfAE8H8G6zMeqOBqqjADKCKCMAMoIoIwAygigLO84IIQfMcfMMceKnfNNzN4zoLHDFjZfghqKYPc9oJEItt+Er4vwJAWxvxd0HOEh4BaPUo6HMnZDnyMcZDd73YN8kDKeBf5E7Z3tZYLX8mfL+xO2bZ4z7DeOx3FDCf3oBQAAAABVqW8gpn3OWuR8Q30zYoUdRa1zSrKgCPXOCRcSoe5J+QIi1L8qwniENpalGI7QzrogoxHaWphlMEJ7K+OMRWhzaaKhCPaWJuZi5Jy1OiflYygfO2J1tPLLEQE8xQgEeKYUgQAvKUQgwKnMEQhwTsYIBLgkUwQCTMkQgQBz+KgdI/yI2dgZlu3xEVbGsaC1Nb8uqDAEUEYAZQRQRgBlBFBGAGUEWFvkYQsGYilETPQTIJXACARIKSACAVKbiUCAHCYiECCXCxEIkNOZCATI7SQCATTELghGAswxGzBE+ANvEdwxcJvLEgAAAABJRU5ErkJggg==);
}

.vip_login_p .vip_container .login-box .login-warp-new .pwd:before {
  content: "";
  position: absolute;
  left: -110px;
  top: -2px;
  display: inline-block;
  width: 112px;
  height: 32px;
  background-size: cover;
}

.vip_login_p .vip_container .login-box .login-warp-new .qrcodemodule .scan-box {
  position: relative;
  width: 100%;
}

.vip_login_p
  .vip_container
  .login-box
  .login-warp-new
  .qrcodemodule
  .scan-box
  .scan-wrapper {
  text-align: center;
}

.vip_login_p
  .vip_container
  .login-box
  .login-warp-new
  .qrcodemodule
  .scan-box
  .scan-wrapper
  .scan-title {
  padding: 24px 0 48px 0;
  font-size: 16px;
  line-height: 30px;
  color: #333;
}

.vip_login_p
  .vip_container
  .login-box
  .login-warp-new
  .qrcodemodule
  .scan-box
  .scan-wrapper
  .scan-title
  .js-open-tyc-app-link {
  position: relative;
  z-index: 2;
}

.vip_login_p
  .vip_container
  .login-box
  .login-warp-new
  .qrcodemodule
  .scan-box
  .scan-wrapper
  .scan-img {
  position: relative;
  width: 180px;
  height: 18 0;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin: 0 auto;
  padding: 9px;
  text-align: center;
}

.vip_login_p
  .vip_container
  .login-box
  .login-warp-new
  .qrcodemodule
  .scan-box
  .scan-wrapper
  .scan-img
  .scan {
  width: 180px;
  height: 180px;
  cursor: pointer;
  position: relative;
}

.vip_login_p
  .vip_container
  .login-box
  .login-warp-new
  .qrcodemodule
  .scan-box
  .scan-wrapper
  .scan-img
  .scan
  .expire-mask {
  position: absolute;
  top: 50%;
  left: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 184px;
  height: 184px;
  color: #fff;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.vip_login_p
  .vip_container
  .login-box
  .login-warp-new
  .qrcodemodule
  .scan-box
  .scan-wrapper
  .scan-img
  .scan
  .expire-mask
  span {
  display: inline-block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.vip_login_p
  .vip_container
  .login-box
  .login-warp-new
  .qrcodemodule
  .scan-box
  .scan-wrapper
  .scan-img
  .scan-error {
  z-index: 1;
  position: absolute;
  top: 8px;
  left: 8px;
  display: none;
  width: 182px;
  height: 182px;
  line-height: 21px;
  font-size: 16px;
  color: #0084ff;
  text-align: center;
  background-color: hsla(0, 0%, 100%, 0.96);
}

.vip_login_p
  .vip_container
  .login-box
  .login-warp-new
  .qrcodemodule
  .scan-box
  .scan-wrapper
  .scan-success-tip {
  display: none;
  margin-top: 42px;
  font-size: 14px;
  line-height: 22px;
}

.vip_login_p
  .vip_container
  .login-box
  .login-warp-new
  .qrcodemodule
  .scan-box
  .scan-wrapper
  .scan-success-tip
  .tip {
  color: #333;
}

.vip_login_p
  .vip_container
  .login-box
  .login-warp-new
  .qrcodemodule
  .scan-box
  .scan-wrapper
  .scan-success-tip
  .scan-refresh {
  margin-top: 12px;
}

.vip_login_p
  .vip_container
  .login-box
  .login-warp-new
  .qrcodemodule
  .scan-box
  .scan-tips {
  margin: 74px 0 0;
  line-height: 24px;
  color: #999;
  text-align: center;
}

.vip_login_p .vip_container .login-box .login-warp-new .loginmodule .sign-in {
}

.vip_login_p
  .vip_container
  .login-box
  .login-warp-new
  .loginmodule
  .sign-in
  .title-tabs
  .el-tabs__header {
  margin-bottom: 32px;
}

/* .vip_login_p
  .vip_container
  .login-box
  .login-warp-new
  .loginmodule
  .sign-in
  .title-tabs
  .el-tabs__nav {
  width: 100%;
} */

.vip_login_p
  .vip_container
  .login-box
  .login-warp-new
  .loginmodule
  .sign-in
  .title-tabs
  .el-tabs__item {
  width: 50%;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.vip_login_p
  .vip_container
  .login-box
  .login-warp-new
  .loginmodule
  .sign-in
  .only-one
  .el-tabs__item {
  width: 100%;
}

.vip_login_p
  .vip_container
  .login-box
  .login-warp-new
  .loginmodule
  .sign-in
  .login-form
  .el-input {
  width: 356px;
}

.vip_login_p
  .vip_container
  .login-box
  .login-warp-new
  .loginmodule
  .sign-in
  .sign-operation {
  font-size: 14px;
  line-height: 22px;
  overflow: hidden;
  padding-bottom: 18px;
  padding-top: 0;
  text-align: right;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.vip_login_p
  .vip_container
  .login-box
  .login-warp-new
  .loginmodule
  .sign-in
  .sign-operation
  .sign-tip {
  color: #999;
  text-align: center;
}

.vip_login_p
  .vip_container
  .login-box
  .login-warp-new
  .loginmodule
  .sign-in
  .login-btn {
  display: block;
  width: 100%;
}

.vip_login_p .login-bottom {
  font-size: 12px;
  color: #999;
  text-align: center;
  margin-top: 10px;
}

.vip_login_p .vip_container .login-box .login-warp-new .pwdmodule .sign-in {
  width: 356px;
  display: inline-block;
  vertical-align: top;
}

.vip_login_p
  .vip_container
  .login-box
  .login-warp-new
  .pwdmodule
  .sign-in
  .login-title {
  position: relative;
  padding-bottom: 24px;
  padding-top: 22px;
  font-size: 18px;
  text-align: center;
}

.vip_login_p
  .vip_container
  .login-box
  .login-warp-new
  .pwdmodule
  .sign-in
  .login-title
  .login-back {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 0;
  font-size: 14px;
}

.vip_login_p
  .vip_container
  .login-box
  .login-warp-new
  .pwdmodule
  .sign-in
  .pwd-form
  .pr {
  position: relative;
  margin-bottom: 50px;
}

.vip_login_p
  .vip_container
  .login-box
  .login-warp-new
  .pwdmodule
  .sign-in
  .pwd-form
  .pr
  .login-operation {
  position: absolute;
  right: 0;
}

.vip_login_p
  .vip_container
  .login-box
  .login-warp-new
  .pwdmodule
  .sign-in
  .submit-btn {
  display: block;
  width: 100%;
}

.vip_login_p .vip_container .copyt-right-box {
  text-align: center;
  position: absolute;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

/* 自己的 */

.loginp-tabs {
  padding: 10px 0px 0px 0px;
}

.loginp-tabs .el-tabs__header {
  background-color: #ffffff; /* 设置背景颜色 */
  border-radius: 20px; /* 设置椭圆形状 */
  margin-bottom: 20px;
  padding: 0px;
  width: 100%;
  text-align: center;
  display: grid;
  place-items: center;
}

.loginp-tabs .el-tabs__item {
  color: #606266; /* 设置文字颜色 */
  border: none; /* 去掉边框 */
  height: 35px;
  line-height: 35px;
  margin-right: 10px;
  padding: 0px;
  width: 170px;
  text-align: center;
  font-size: 16px;
  color: #000000;
  /* padding: 0 15px; */
}

.login_icon {
  width: 2rem;
  height: 2rem;
  color: #cacdd5;
}
</style>
