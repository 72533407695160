<template>
  <div></div>
</template>

<script>
import { mapState } from "vuex";

export default {
  metaInfo() {
    return {
      title: this.websiteName,
      meta: [
        { name: "description", content: this.description },
        { name: "keywords", content: this.keywords },
        { property: "og:title", content: this.websiteName },
        // 其他 meta 标签...
      ],
    };
  },
  name: "App",
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      templete: "",
      isRouterAlive: true,
      websiteName: "",
      description: "",
      keywords: "",
    };
  },
  components: {
    // Header,
    // Footer,
  },
  computed: {
    ...mapState({
      //查询首页模板名称
      webSetting: (state) => state.index.webSetting,
    }),
  },
  mounted() {
    // this.checkIfWechatBrowser();
  },
  created() {
    this.reload();
  },
  methods: {
    // checkIfWechatBrowser() {
    //   const userAgent = navigator.userAgent.toLowerCase();
    //   if (userAgent.includes("micromessenger")) {
    //     window.location.href =
    //       "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxcf089e95e8f130b9&redirect_uri=http://66hhl.cn/mindex&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect";
    //   }
    // },

    reload() {
      let data = {};

      //查询租户模板信息
      this.$store.dispatch("index/getWebSetting", data).then(() => {
        this.$router.push(this.webSetting.router);
      });
    },
  },
};
</script>
