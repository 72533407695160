<template>
  <div class="xcx-preview">
    <!-- <span class="el-icon-close" @click="$parent.showPreview = false"></span> -->
    <div class="xcx-module-container">
      <!--控件部份-->
      <div v-for="(item, index) in list.controlList" :key="index">
        <!--页面背景-->
        <pagebg :base="item.base"></pagebg>
      </div>

      <div class="xcx-module">
        <!--DIY组件部份-->
        <Module :moduleList="list.moduleList" :prev="true" ref="module" style="min-height: calc(100vh - 300px)" />
      </div>
    </div>

    <!-- 首页弹框 -->
    <el-dialog destroy-on-close append-to-body :visible.sync="noticeVisible" class="success-dialog el_dialog_radius"
      width="40%">
      <div class="success-dialog-container">
        <div class="title">系统公告</div>

        <div class="dilalog_img">
          <img class="fail" src="@/assets/image/notice_icon.png" />
        </div>

        <div class="ql-editor" v-html="noticeContent" />

        <div class="footer">
          <div class="but" @click="noticeVisible = false">关闭</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Module from "@/pages/template/pc/module/module.vue";
import pagebg from "@/pages/template/pc/module/pagebg.vue";
import { getWebTemplate, getIndexNotice } from "../api/index";
// import { Toast } from "vant";

export default {
  name: "index",
  components: { Module, pagebg },
  data() {
    return {
      list: [],
      noticeVisible: false,
      noticeContent: undefined,
      loginStatus: false,
      noticeList: [],
    };
  },
  created() {
    //加载用户信息
    this.$store.dispatch("user/info");

    this.initData();
  },
  methods: {
    initData() {
      let data = {};
      getWebTemplate(data).then((response) => {
        if (response.status == 0) {
          this.list = response.data;
        }
      });

      //延迟500毫秒执行方法
      setTimeout(() => {
        this.getNotice();

        //加载底部栏
        // this.queryFooter();
      }, 500);
    },

    /**
     * 查询首页弹框公告
     */
    getNotice() {
      getIndexNotice().then((response) => {
        if (response.result) {
          this.noticeVisible = true;
          this.noticeContent = response.result.content;
          console.log(response);
        }
      });
    },

    /**
     * 展示公告详情
     */
    toNoticeDetails(data) {
      this.$router.push({
        path: "noticeDetails",
        query: { noticeId: data.id },
      });
    },
  },
};
</script>
<style scoped>
/deep/.el-dialog {
  border-radius: 10px;
}

.ql-editor ol li:before {
    content: '';
}
</style>
