<template>
  <!-- :style="[
      !module.base.m ? { 'margin-top': '0' } : {},
      {
        padding:
          '0px ' + module.base.margins + 'px 0px ' + module.base.margins + 'px',
      },
    ]" -->
  <div class="module-common pc-common">
    <div class="model_guanggao">
      <el-row :gutter="10">
        <el-col
          v-for="(item, index) in module.list"
          :key="index"
          :span="24 / module.max"
        >
          <div class="model_guanggao_img">
            <div class="btn-wrap">
              <a :href="item.link" target="_blank">
                <img :src="item.imageUrl" class="model_guanggao_Banner" />
              </a>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { queryBannerList } from "@/api/index";

export default {
  props: ["module"],
  data() {
    return {
      bannerList: [],
    };
  },

  created() {
    //获取轮播图
    this.getBannerList();
  },
  methods: {
    /**
     * 查询轮播图
     */
    getBannerList() {
      let data = { banType: "PC_2" };

      queryBannerList(data).then((response) => {
        if (response.status == 0) {
          this.bannerList = response.result;
        }
      });
    },
  },
};
</script>

<style>
.model_guanggao {
  margin-top: 10px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.model_guanggao_Banner {
  border-radius: 8px;
  width: 100%;
}
</style>
