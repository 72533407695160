<template>
  <!-- :style="[
      !module.base.m ? { 'margin-top': '0' } : {},
      {
        padding:
          '0px ' + module.base.margins + 'px 0px ' + module.base.margins + 'px',
      },
    ]" -->
  <div class="module-common pc-common">
    <div class="plane">
      <div class="banner">
        <el-carousel>
          <el-carousel-item
            indicator-position="none"
            style="height: 390px"
            v-for="(item, index) in module.list"
            :key="index"
          >
            <a :href="item.link" target="_blank">
              <img
                style="border-radius: 10px; width: 100%; height: 100%"
                :src="item.imageUrl"
              />
            </a>
          </el-carousel-item>
        </el-carousel>
      </div>

      <div class="right">
        <template v-if="this.loginStatus">
          <div class="user-info">
            <div class="el-image avatar">
              <div class="el-image">
                <img :src="avatarPicUrl" class="el-image__inner" />
              </div>
            </div>
            <div class="user-name">
              <span>欢迎您 {{ username }}</span>
            </div>
            <div class="login-box">
              <el-button size="small" type="primary" @click="toUserInfo"
                >个人中心</el-button
              >
              <el-button size="small" @click="handleLogout()"
                >退出登录</el-button
              >
            </div>
          </div>
        </template>

        <template v-else>
          <div class="user-info">
            <div class="el-image avatar">
              <div class="el-image">
                <img src="./Juicy-1.png" class="el-image__inner" />
              </div>
            </div>
            <div class="user-name">
              <span>Hi，欢迎回来</span>
            </div>
            <div class="login-box">
              <el-button size="small" type="primary" @click="clickLogin()"
                >登录 / 注册</el-button
              >
            </div>
          </div>
        </template>

        <div class="login-enjoy">
          <div class="title">
            <div class="befor"></div>
            登录即享
            <div class="after"></div>
          </div>
          <div class="icon-show">
            <div class="item">
              <img src="@/assets/image/icon_zhekou.svg" style="width: 40px" />
              <div class="name">专属折扣</div>
            </div>
            <div class="item">
              <img src="@/assets/image/icon_youhui.svg" style="width: 40px" />
              <div class="name">专属优惠</div>
            </div>
            <div class="item">
              <img src="@/assets/image/icon_tequan.svg" style="width: 40px" />
              <div class="name">优惠特权</div>
            </div>
            <div class="item">
              <img src="@/assets/image/icon_dijia.svg" style="width: 40px" />
              <div class="name">低价预定</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <PLogin v-if="dialogLoginVisible" @close="clickLogin" />
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
import { mapGetters, mapState } from "vuex";
import PLogin from "@/pages/pc/common/login";
export default {
  props: ["module"],
  components: { PLogin },
  data() {
    return {
      webLogo: "",
      loginStatus: false,
      loginForm: {
        username: "",
        password: "",
        checkPass: "",
        inviterCode: "",
      },
      dialogLoginVisible: false,
      dialogRegisterVisible: false,
      products: [],
      catesList: [],
    };
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      producCategorytList: (state) => state.index.producCategorytList,
      bannerList: (state) => state.index.bannerList,
      newList: (state) => state.index.newList,
      recommendList: (state) => state.index.recommendList,
      hotList: (state) => state.index.hotList,
      indexBrandList: (state) => state.index.indexBrandList,
      // normalNewsList: (state) => state.index.normalNewsList,
      username: (state) => state.user.username,
      avatarPicUrl: (state) => state.user.avatarPicUrl,
      email: (state) => state.user.email,
      phone: (state) => state.user.phone,
      userid: (state) => state.user.userid,
      balanceTotal: (state) => state.balance.balanceTotal,
      totalWithdrawRejected: (state) => state.balance.totalWithdrawRejected,
      totalRechargeSuccess: (state) => state.balance.totalRechargeSuccess,
      balanceCanWithdraw: (state) => state.balance.balanceCanWithdraw,
      totalWithdrawing: (state) => state.balance.totalWithdrawing,
      totalWithdrawSuccess: (state) => state.balance.totalWithdrawSuccess,
      tableDatas: (state) => state.balance.list,
      brandListByCategoryId: (state) => state.index.brandListByCategoryId,
    }),
  },
  mounted() {
    //验证是否登录
    this.verifyIsLogin();
    //查收商品分类
    this.getCateList();
    //获取轮播图
    this.getBannerList();
  },
  created() {},
  methods: {
    /**
     * 判断用户是否登录
     */
    verifyIsLogin() {
      const hasToken = getToken();
      if (hasToken) {
        this.loginStatus = true;
        this.$store.dispatch("user/info");
      }
    },

    /**
     * 跳转到商品分类
     */
    toCategory() {
      this.$router.push({ path: "category" });
    },

    /**
     * 跳转到商品列表
     */
    toProductList(value) {
      this.$router.push({
        path: "pcate",
        query: { categoryId: value.id, categoryPid: value.categoryPid },
      });
    },

    /**
     * 查询商品分类
     */
    getCateList() {
      // 首页tab商品
      let data = { offset: 0, limit: 10 };
      this.$store.dispatch("index/queryProducCategorytList", data).then(() => {
        this.catesList = this.producCategorytList.list;
      });
    },

    /**
     * 查询轮播图
     */
    getBannerList() {
      let data = { banType: "PC" };
      this.$store.dispatch("index/queryBannerList", data).then(() => {
        // this.$forceUpdate();
      });
    },

    /**
     * 登录
     */
    clickLogin() {
      // this.dialogLoginVisible = !this.dialogLoginVisible;
      this.$router.push("/plogin");
    },

    toUserInfo() {
      this.$router.push({ path: "/user_info" });
    },

    /**
     * 退出登录
     */
    handleLogout() {
      this.$store
        .dispatch("user/logout", this.loginForm)
        .then(() => {
          location.reload();
          this.$message({
            message: "注销成功！",
            type: "success",
          });
        })
        .catch(() => {
          this.$message({
            message: "注销失败！",
            type: "warning",
          });
        });
    },

    showProducts(category) {
      this.products = category;
      console.log(this.products);
    },

    hideProducts() {
      this.products = [];
    },
  },
};
</script>

<style>
.plane {
  height: 390px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  position: relative;
}

.plane .left {
  height: 100%;
  width: 240px;
  border-radius: 5px;
  background: #3b8cfe;
  display: flex;
  flex-direction: column;
  position: relative;
}

/* .plane .left .item {
  height: 82px;
  padding: 17px 17px 9px 17px;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.14);
  display: flex;
  flex-direction: column;
  gap: 8px;
  cursor: pointer;
} */

.plane .left .item .item-title {
  font-size: 14px;
  height: 19px;
  font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
  font-weight: 700;
  color: #fff;
  display: flex;
  align-items: center;
  position: relative;
}

.plane .left .item .item-title .icon {
  width: auto;
  /* height: 15px; */
  margin-right: 6px;
}

.plane .left .item .item-list {
  font-size: 14px;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
  font-weight: 400;
  color: #fff;
}

.plane .left .item .item-list .link {
  cursor: pointer;
}

.plane .left .item .item-list .link:after {
  content: "/";
}

.plane .left .item .item-list .link {
  cursor: pointer;
}

.plane .item-child-box {
  display: grid;
  grid-template-columns: repeat(5, auto);
  justify-content: start;
  justify-items: center;
  align-content: start;
  height: 407px;
  background: #fff;
  /* box-shadow: 0 2px 7px 2px rgba(220, 224, 227, 0.8); */
  border-radius: 9px;
  position: absolute;
  left: 245px;
  top: 0;
  z-index: 5;
  transition: all 0.5s;
  min-width: 800px;
}

.plane .item-child-box .goods-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 170px;
  gap: 5px;
  height: 135px;
  cursor: pointer;
}

.plane .item-child-box .goods-box .img-box {
  width: 60px;
  height: 60px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 5px 15px rgba(0, 184, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.plane .item-child-box .goods-box .img-box .goods-img {
  width: 52px;
  height: 52px;
  border-radius: 50%;
}

.plane .item-child-box .goods-box .goods-name {
  font-size: 14px;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
  font-weight: 400;
  color: #252525;
}

.plane .banner {
  height: 100%;
  flex: 1;
  border-radius: 5px;
  /* background-color: #fff; */
  position: relative;
}

.plane .right {
  height: 100%;
  width: 290px;
  border-radius: 5px;
  background-color: #fff;
  padding: 0 13px;
}

.plane .right .user-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 186px;
  border-bottom: 1px dashed #e4e4e4;
  gap: 12px;
}

.plane .right .user-info .avatar {
  width: 65px;
  height: 65px;
  border-radius: 50%;
}

.plane .right .user-info .user-name {
  font-size: 14px;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
  font-weight: 400;
  /* color: #252525; */
}

.plane .right .login-enjoy {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.plane .right .login-enjoy .title {
  margin-top: 10px;
  font-size: 16px;
  font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
  font-weight: 500;
  color: #252525;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 17px;
  gap: 7px;
}

.plane .right .login-enjoy .title .befor {
  position: relative;
  width: 47px;
  height: 3px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 3px;
  flex-direction: column;
  background-color: #f2f3f5;
}

.plane .right .login-enjoy .title .after {
  width: 47px;
  height: 3px;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 3px;
  flex-direction: column;
  background-color: #f2f3f5;
}

.plane .right .login-enjoy .icon-show {
  margin-top: 14px;
  width: 158px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 28px;
}

.plane .right .login-enjoy .icon-show .item {
  width: 50px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3px;
}

.plane .right .login-enjoy .icon-show .item .icon {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.plane .right .login-enjoy .icon-show .item .name {
  font-size: 12px;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
  font-weight: 400;
  /* color: #252525; */
}

.el-carousel__container {
  height: 390px;
}

.plane .left .more {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.plane .left .more .left-btn {
  width: 170px;
  height: 40px;
  background: hsla(0, 0%, 100%, 0.18);
  border-radius: 4px 4px 4px 4px;
  border: none;
  font-size: 14px;
  font-family: Alibaba PuHuiTi-Regular, Alibaba PuHuiTi;
  font-weight: 400;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

.index-icon {
  background-color: #e2eeff;
  border-radius: 50%;
  padding: 10px;
  /* width: 100px; */
  font-size: 22px;
  color: #3175d4;
}

/* 新的 */

.index-topcata {
  /* margin-top: 13px; */
  /* width: 100%; */
  display: flex;
  justify-content: space-between;
}

.index-topcata .left {
  width: 240px;
  height: 390px;
  padding: 10px 0;
  background-color: #3b8cfe;
  border-radius: 5px;
  position: relative;
}

.cata-item {
  width: 100%;
  height: 80px;
  display: block;
  padding: 10px 14px;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.14);
}

.cata-item-header {
  width: 100%;
  display: flex;
  align-items: center;
}

.cata-item-header .icon {
  font-size: 18px;
  margin-top: -2px;
  color: #fff;
}

.cata-item-header .title {
  font-size: 15px;
  margin-left: 4px;
  font-weight: 700;
  color: #fff;
}

.cata-item-son {
  margin-top: 6px;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
}

.cata-item-son .item {
  /* width: 50%; */
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cata-item-son .item .pic {
  width: 22px;
  height: 22px;
  border-radius: 13px;
  overflow: hidden;
}

.cata-item-son .item .pic img {
  width: 100%;
  height: 100%;
}

.cata-item-son .item .title {
  display: block;
  font-size: 13px;
  color: #fff;
}

.cata-child {
  transition: background-color 0.2s;
}

.cata-child:hover {
  background-color: #ffffff1a;
}

.cata-child:hover .cata-float {
  display: block;
}

.cata-item span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #333;
}

.right-icon {
  width: 12px;
  height: 28px;
  display: flex;
  align-items: center;
  color: #ccc;
}

.cata-float {
  display: none;
  position: absolute;
  left: 230px;
  top: 0;
  width: 800px;
  height: 400px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 5px 20px #0000000d;
  padding: 20px;
  z-index: 2;
}

.cata-float-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: wrap;
  align-content: flex-start;
  overflow: hidden;
}

.cata-float-wrap .item {
  width: 16.666666%;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: background-color 0.2s;
}

.cata-float-wrap .item:hover {
  background: #f9fafa;
}

.cata-float-wrap .item .pic {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border: 2px solid #fff;
  box-shadow: 0 3px 16px -8px var(--ksy--diycolor1);
  overflow: hidden;
}

.cata-float-wrap .item .pic img {
  width: 100%;
  height: 100%;
}

.cata-float-wrap .item span {
  margin-top: 8px;
  font-size: 14px;
  color: #333;
}

.cata-float-wrap .item:hover span {
  color: var(--ksy--diycolor1);
}

.cata-all {
  margin: 5px 10px 10px 20px;
  display: flex;
  width: 200px;
  height: 40px;
  background: rgb(255 255 255 / 20%);
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

.cata-all:hover {
  background: rgb(255 255 255 / 30%);
}
</style>
