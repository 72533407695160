<template>
  <div>
    <!-- 样式1 -->
    <div class="item-style1" v-show="module.base.s == 0">
      <Index1 :module="module" />
    </div>

    <!-- 样式2 -->
    <div class="item-style1" v-show="module.base.s == 1">
      <Index2 :module="module" />
    </div>

    <!-- 样式3 -->
    <div class="item-style1" v-show="module.base.s == 2">
      <Index3 :module="module" />
    </div>

  </div>
</template>

<script>
import Index1 from "./index_1.vue";
import Index2 from "./index_2.vue";
import Index3 from "./index_3.vue";


export default {
  props: ["module"],
  components: { Index1, Index2, Index3 },
  data() {
    return {
      nav: [],
    };
  },
  computed: {},
};
</script>
