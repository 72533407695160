<template>
  <div class="module-common pc-common">
    <div class="index-3" v-for="(value, key, index) in catesList" :key="index">

      <div class="public-index-list">

        <div class="wrap">

          <div class="item ing">
            <div class="title">{{ value.categoryName }}</div>
            <div class="list">
              <a v-for="(item, key, index) in value.children" :key="index" style="cursor: pointer;"
                @click="toProductList(item)">
                <van-image width="60" height="60" :src="item.prcUrl" round />
                <span>{{ item.categoryName }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  props: ["module"],
  components: {},
  data() {
    return {
      loginStatus: false,
      catesList: [],
    };
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      producCategorytList: (state) => state.index.producCategorytList,
    }),
  },
  created() {
    //查收商品分类
    this.getCateList();
  },
  methods: {
    /**
     * 查询商品分类
     */
    getCateList() {
      // 首页tab商品
      let data = { offset: 0, limit: 10 };
      this.$store.dispatch("index/queryProducCategorytList", data).then(() => {
        this.catesList = this.producCategorytList.list;
      });
    },

    /**
     * 跳转到商品列表
     */
    toProductList(value) {
      this.$router.push({
        path: "pcate",
        query: { categoryId: value.id, categoryPid: value.categoryPid },
      });
    },
  },
};
</script>

<style>
/* 新的 */
.public-index-list .wrap .item {
  margin-bottom: 20px;
  background: #fff;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 5%);
  padding: 20px;
  border-radius: 8px;
}


.public-index-list .wrap .item .title {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  text-indent: 15px;
  height: 24px;
  line-height: 24px;
}

.public-index-list .wrap .item.ing .title::after {
  background-color: #5599ff;
  background-image: linear-gradient(to bottom, #5599ff, #1ba4ff);
}

.public-index-list .wrap .item .title::after {
  content: '';
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 5px;
  border-radius: 5px;
  background: #ccc;
}

.public-index-list .wrap .item .list {
  display: flex;
  flex-wrap: wrap;
}

.public-index-list .wrap .item .list a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 16.666666%;
  margin-top: 30px;
}

.public-index-list .wrap .item .list a img {
  width: 60px;
  height: 60px;
  border-radius: 40px;
  box-shadow: 0px 5px 15px rgb(0 184 255 / 20%);
  padding: 5px;
  background: #fff;
}

.public-index-list .wrap .item .list a span {
  font-size: 14px;
  margin-top: 10px;
  color: #333;
}
</style>
