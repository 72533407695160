<template>
  <div class="module-common pc-common">
    <div class="feature-box">
      <div class="soft-header-title" style="margin-bottom: 0px">
        <div class="title">精选推荐</div>
        <!-- <div class="feature-box-button">换一换</div> -->
      </div>
      <div class="soft-box">
        <el-row :gutter="10">
          <el-col :span="6" v-for="(item, index) in this.catesList" :key="index">
            <div class="soft-item" @click="toProductDetails(item)">
              <!-- <img :src="item.picUrl" class="soft-img" /> -->


              <!-- 商品图片 -->
              <div style="margin-right: 12px;">
                <el-image :src="item.picUrl" class="soft-img">
                  <template slot="error">
                    <el-image :src="$defaultImage" class="soft-img"></el-image>
                  </template>
                </el-image>
              </div>


              <div class="soft-info" >
                <div class="soft-name">
                  <div class="title ellipsis" style="max-width: 164px">
                    {{ item.productName }}
                  </div>
                </div>
                <div class="soft-desc ellipsis" style="width: 164px; margin-top: 15px">
                  <span v-html="$decimalPrice2(item.price)"></span>

                  <span style="float: right; margin-top: 3px" v-if="item.salsCount">
                    {{ item.salsCount }}人已购买
                  </span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  props: ["module"],
  components: {},
  data() {
    return {
      webLogo: "",
      loginStatus: false,
      goodsName: "",
      catesList: [],
    };
  },
  computed: {
    ...mapGetters({}),
    ...mapState({
      recommendList: (state) => state.index.recommendList,
    }),
    slideGroups() {
      // 将轮播项按照每行展示的数量进行分组
      console.log(this.catesList);
      const groups = [];
      for (let i = 0; i < this.catesList.length; i += 3) {
        groups.push(this.catesList.slice(i, i + 3));
      }
      console.log(groups);
      return groups;
    },
    moduleBgStyle() {
      return {
        background: `linear-gradient(122235deg, ${this.module.moduleBg.bgColor}, ${this.module.moduleBg.fzColor})`,
        color: this.module.moduleBg.fontColor,
      };
    },
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      let data = { offset: 0, limit: 10 };
      this.$store.dispatch("index/queryRecommendList", data).then(() => {
        this.catesList = this.recommendList;
      });
    },

    //跳转商品详情界面
    toProductDetails(v) {
      this.$router.push(`/pgoodsDetail/${v.id}`);
    },
  },
};
</script>

<style scoped>
.feature-box {
  padding: 20px;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 8px;
}

.soft-header-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 28px;
  margin-bottom: 20px;
}

.soft-header-title .title {
  color: #000;
  font-family: "PingFang SC";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.soft-header-title .feature-box-button {
  color: #1232da;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  cursor: pointer;
}

/* .feature-box .soft-box {
  display: flex;
  flex-wrap: wrap;
} */

.feature-box .soft-item {
  display: flex;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* flex: 1 1 0; */
  padding: 12px 20px;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  background: #fff;
  height: 90px;
  box-sizing: border-box;
  margin-top: 20px;
  cursor: pointer;
}

.feature-box .soft-item .soft-img {
  width: 62px;
  height: 62px;
  border-radius: 8px;
}

.feature-box .soft-item .soft-name {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  height: 20px;
  margin-right: 5px;
}

.feature-box .soft-item .title {
  color: #181818;
  font-family: "PingFang SC";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  max-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 5px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.feature-box .soft-item .tag {
  display: flex;
  padding: 1px 4px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  color: #00ab59;
  font-family: "PingFang SC";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 2px;
  background: rgba(0, 179, 93, 0.1);
  margin-right: 3px;
}

.feature-box .soft-item .tag {
  display: flex;
  padding: 1px 4px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  color: #00ab59;
  font-family: "PingFang SC";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 2px;
  background: rgba(0, 179, 93, 0.1);
  margin-right: 3px;
}

.feature-box .soft-item .tag img {
  width: 8px;
  height: 8px;
  margin-right: 2px;
}

.feature-box .soft-item .soft-desc {
  color: #999;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 164px;
  margin-top: 10px;
}

.feature-box .down-button {
  display: none;
  position: absolute;
  right: 20px;
  top: 30px;
  color: #fff;
  border-radius: 37px;
  background: #2049ee;
  text-align: center;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  width: 60px;
  height: 30px;
}
</style>
